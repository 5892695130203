import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/style.css"
import {useState} from "react";
import designGif from '../images/product/design-certificate.gif'
import groupGif from '../images/product/create-group.gif'
import certificateGIF from '../images/product/publish-certificate.gif'

const FeaturesPage = () => {


    return (
        <Layout>
            <section className={"section--row hcenter"}>
                <h1 className="text-center">Your Ultimate Tool For All Your<br/><span
                    className={"text-blue"}>Digital Certificate and Badge Management</span></h1>
                <p className="text-center">
                    It’s not just about infrastructure and underlying technology, Hyperstack provides <br/>a whole range of
                    features that will enable you to take customization and branding to a whole new level.

                </p>
                <br/>
            </section>

            <section className={"section--reverse"}>
                <aside>
                    <h3 className="title">Branding & White-labeling</h3>
                    <p>At Hyperstack, you can issue your digital certificates and badges from your domain and email address
                        with completely customizable branding on your digital credential, as well as your own verification
                        page.</p>
                </aside>
                <aside>
                    <StaticImage src={"../images/product/branding.png"} alt={"Hyperstack Branding"}/>
                </aside>
            </section>

            <section>
                <aside>
                    <h3 className="title">Dedicated <span className="text-blue">Verification Page</span></h3>
                    <p>Hyperstack’s platform offers a highly dynamic built-in blockchain solution to verify your digital
                        credentials which can be used by background verification agencies and recruiters. This helps in
                        preventing huge waiting times and faster hiring of your students.
                    </p>
                </aside>
                <aside>
                    <br/>
                    <StaticImage src={"../images/product/verification-page.png"}
                                 alt={"Hyperstack Dedicated Verification Page"}/>
                    <br/>
                </aside>
            </section>

            <section className={"section--reverse"}>
                <aside>
                    <h3 className="title"><span className="text-blue">Advanced Analytics</span> with 20+ Insights</h3>
                    <p>Our measurements and insights module allows for multiple data points about your students and allows
                        prospects to be captured which in turn can be fed into your marketing and sales strategies.</p>
                </aside>
                <aside>
                    <StaticImage src={"../images/product/analytics.png"} alt={"Hyperstack Analytics"}/>
                </aside>
            </section>

            <section>
                <aside>
                    <h3 className="title"><span className="text-blue">Multi-Blockchain</span> Support</h3>
                    <p>Our blockchain based system makes sure your certificates are authentic and secures your
                        organizational identity and eliminates the possibility of fraud. With our multi-blockchain support
                        you can issue digital credentials on any blockchain with your own preference.
                    </p>
                </aside>
                <aside>
                    <StaticImage src={"../images/product/multi-blockchain.png"} alt={"Hyperstack Blockchain"}/>
                </aside>
            </section>

            <section className={"section--reverse"}>
                <aside>
                    <h3 className="title">Powerful <span className="text-blue">Design Studio</span></h3>
                    <p>Our Design Studio allows for native design of digital badges and certificates which support standards
                        such as open badges 2.0. We support integration with popular platforms such as Canva which allows
                        for professional design to be completed or predesigned credentials to be uploaded very quickly.
                    </p>
                </aside>
                <aside>
                    <StaticImage src={"../images/product/design-studio.png"} layout="fixed"
                                 alt={"Hyperstack Design Studio"}/>
                </aside>
            </section>

            <section>
                <aside>
                    <h3 className="title"><span className="text-blue">Endlessly</span> extensible</h3>
                    <p>Integrate Hyperstack’s digital credentialing tools with popular learning management platforms around
                        the world. With our easy to use integration module, connect to any app or service within 10
                        minutes.</p>
                </aside>
                <aside>
                    <StaticImage src={"../images/product/integrations.png"} alt={"Hyperstack Inteagrations"}/>
                </aside>
            </section>

        </Layout>
    )

}
export const Head = () => <Seo
    title="Features - Hyperstack"
    decription="Your ultimate credential management tool for all Your digital Certificates and Badges"
/>

export default FeaturesPage
